<template>
  <div class="program-modal">
    <base-loader
      v-if="isLoading"
      modifiers="center"
    />

    <program-level
      v-if="!isLoading"
      v-on:cancelled="onProgramLevelEvent"
      v-on:confirmed="onProgramLevelEvent"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ProgramLevel from '@/components/program/ProgramLevel';
import BaseLoader from '@/components/BaseLoader';

export default {
  components: {
    ProgramLevel,
    BaseLoader,
  },

  props: {
    programId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
    };
  },

  computed: {
    ...mapGetters('program', ['currentProgram']),
    ...mapGetters('program', ['inactivePrograms']),
  },

  async created() {
    await this.startProgram(this.programId);

    this.isLoading = false;
  },

  methods: {
    ...mapActions({
      startProgram: 'program/start',
    }),

    onProgramLevelEvent() {
      this.$router.push({
        name: 'program',
        params: {
          programId: this.currentProgram.id,
          periodId: this.currentProgram.active_period,
        },
      });

      this.$emit('close');
    },
  },
};
</script>
